<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          v-if="catalogType === 25"
          cols="4"
        >
          <b-form-group
            label="Potencia"
            label-for="valor"
          >
            <b-form-input
              v-model.number="item.valor"
              type="number"
              name="valor"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="[7, 16, 19, 25, 33].includes(catalogType) ? 4 : 7">
          <b-form-group
            :label="catalogType !== 25 ? 'Nombre' : 'Descripción'"
            label-for="nombre"
          >
            <b-form-input
              v-model="item.nombre"
              name="nombre"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="catalogType === 33"
          cols="2"
        >
          <b-form-group>
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <b-form-input
                v-model="item.codigo"
                name="nombre"
                :disabled="editable"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="catalogType === 33"
          cols="2"
        >
          <b-form-group>
            <b-form-group
              label="Código Nocturno"
              label-for="codigoNocturno"
            >
              <b-form-input
                v-model="item.codigoNocturno"
                name="nombre"
                :disabled="editable"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="catalogType === 7"
          cols="4"
        >
          <b-form-group
            label="Tipo"
            label-for="tipoFalla"
          >
            <b-form-select
              v-model="item.tipoFalla"
              :options="tiposFallas"
              name="tipoFalla"
            />
          </b-form-group>
        </b-col>
        <!-- TIPO DE TRABAJO CONSERVACION -->
        <b-col
          v-if="catalogType === 16"
          cols="4"
        >
          <b-form-group
            label="Tipo"
            label-for="tipo"
          >
            <b-form-select
              v-model="item.tipo"
              :options="tipoConservacion"
              name="tipo"
            />
          </b-form-group>
        </b-col>
        <!-- TIPO DE TRABAJO GABINETE -->
        <b-col
          v-if="catalogType === 19"
          cols="4"
        >
          <b-form-group
            label="Tipo"
            label-for="tipoFalla"
          >
            <b-form-select
              v-model="item.tipo"
              :options="tipoMantenimientos"
              name="tipoFalla"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="[7, 16, 19, 25, 33].includes(catalogType) ? 4 : 5">
          <b-form-group
            label="Estado"
            label-for="active"
          >
            <v-select
              v-model="item.active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option"
              label="title"
              :disabled="editable"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="catalogType === 33"
          cols="9"
        >
          <b-form-group>
            <b-form-group
              label="Descripción"
              label-for="descripcion"
            >
              <b-form-textarea
                v-model="item.descripcion"
                no-resize
                name="nombre"
                :disabled="editable"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="catalogType === 33"
          cols="3"
        >
          <b-form-group label="Requiere cambio de lámpara?">
            <b-form-checkbox
              v-model="item.tipoTrabajo"
              :disabled="editable"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <br>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="editable = false"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateItem"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Actualizar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancel"
          >
            <feather-icon
              icon="SlashIcon"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="havePrice">
        <b-col cols="12">
          <br>
        </b-col>
        <b-col cols="8">
          <h4>Histórico de Costos y Precio de Venta</h4>
        </b-col>
        <b-col cols="4">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            v-b-modal.modal-precios
            variant="outline-primary"
            class="mt-2"
            style="margin-top: -12px !important"
          >
            <feather-icon
              icon="DollarSignIcon"
              class="mr-50"
            />
            <span class="align-middle">Agregar Nuevos Valores</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <list-table-data
            :table-columns="tableColumns"
            :table-data="precios"
            :redirect-edit="''"
            :redirect-show="''"
            :origen="catalogoName"
            :show-icon-new="false"
          />
          <br>
        </b-col>
      </b-row>
    </b-form>
    <!-- modal -->
    <b-modal
      id="modal-precios"
      ref="my-modal"
      title="Ingresar Nuevo Registro de Precios"
      ok-title="Guardar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="costoState"
          :label="`Precio Costo ${mostrarValoresNocturnos ? 'Diurno' : ''}`"
          label-for="name-input"
          invalid-feedback="Precio Costo es Requerido!"
        >
          <b-form-input
            id="name-input"
            v-model.number="itemNewPrecio.costo"
            :state="costoState"
            type="number"
            required
          />
        </b-form-group>
        <b-form-group
          :state="ventaState"
          :label="`Precio Venta ${mostrarValoresNocturnos ? 'Diurno' : ''}`"
          label-for="name-input"
          invalid-feedback="Precio Venta es Requerido!"
        >
          <b-form-input
            id="name-input"
            v-model.number="itemNewPrecio.precioVenta"
            :state="ventaState"
            type="number"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="mostrarValoresNocturnos"
          :state="costoStateNocturno"
          label="Precio Costo Nocturno"
          label-for="name-input"
          invalid-feedback="Precio Costo Nocturno es Requerido!"
        >
          <b-form-input
            id="name-input"
            v-model.number="itemNewPrecio.costoNocturno"
            :state="costoStateNocturno"
            type="number"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="mostrarValoresNocturnos"
          :state="ventaStateNocturno"
          label="Precio Venta Nocturno"
          label-for="name-input"
          invalid-feedback="Precio Venta Nocturno es Requerido!"
        >
          <b-form-input
            id="name-input"
            v-model.number="itemNewPrecio.precioVentaNocturno"
            :state="ventaStateNocturno"
            type="number"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BModal,
  VBModal,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getItem, getPreciosByCatalogo, saveItem } from '@/utils/catalogos'
import ListTableData from '@/components/ListTableData.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    ListTableData,
    BModal,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      catalogoName: '',
      item: {},
      havePrice: false,
      mostrarValoresNocturnos: false,
      nameTable: '',
      costoState: null,
      ventaState: null,
      costoStateNocturno: null,
      ventaStateNocturno: null,
      editable: router.currentRoute.params.action !== 'Edit',
      routeRedirect: '',
      catalogType: 0,
      precios: [],
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
      tiposFallas: [
        {
          value: null,
          text: 'Seleccione un tipo de Falla',
        },
        {
          value: 'FALLA',
          text: 'Tickets de Fallas',
        },
        {
          value: 'MANTENIMIENTO',
          text: 'Tickets de Mantenimientos',
        },
      ],
      tipoMantenimientos: [
        {
          value: null,
          text: 'Seleccione un tipo de Mantenimiento',
        },
        {
          value: 'preventivo',
          text: 'Mantenimientos Preventivo',
        },
        {
          value: 'correctivo',
          text: 'Mantenimientos Correctivo',
        },
      ],
      tipoConservacion: [
        {
          value: null,
          text: 'Seleccione un tipo de Mantenimiento',
        },
        {
          value: 'TRABAJO',
          text: 'Item de Trabajo',
        },
        {
          value: 'CATEGORIA',
          text: 'Categoría de Trabajo',
        },
      ],
      tableColumns: [
        {
          label: 'Precio Costo',
          field: 'costo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Precio de Venta',
          field: 'precioVenta',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Usuario',
          field: 'user',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      itemNewPrecio: {
        active: true,
        costo: 0,
        idOrigen: router.currentRoute.params.id,
        precioVenta: 0,
        costoNocturno: 0,
        precioVentaNocturno: 0,
        tablaOrigen: '',
        user: JSON.parse(localStorage.getItem('userData')).email,
      },
    }
  },
  async created() {
    if (router.currentRoute.params.origen === 'Tipos de Postes') {
      this.catalogType = 1
      this.routeRedirect = '/administracion/tipo-poste'
      this.havePrice = true
      this.nameTable = 'tipo_poste'
    } else if (router.currentRoute.params.origen === 'Estados Lampara') {
      this.catalogType = 2
      this.routeRedirect = '/administracion/estados-lampara'
      this.$route.meta.navActiveLink = 'catalogos-tipo-luminaria'
    } else if (
      router.currentRoute.params.origen === 'Clase de Lampara'
      || router.currentRoute.params.origen === 'Modelo'
    ) {
      this.catalogType = 3
      this.routeRedirect = '/administracion/clase-lampara'
    } else if (router.currentRoute.params.origen === 'Tecnologia Luminaria') {
      this.catalogType = 4
      this.routeRedirect = '/administracion/tec-luminaria'
    } else if (router.currentRoute.params.origen === 'Marcas') {
      this.catalogType = 5
      this.routeRedirect = '/administracion/marcas'
    } else if (router.currentRoute.params.origen === 'Propiedades') {
      this.catalogType = 6
      this.routeRedirect = '/administracion/propiedades'
    } else if (router.currentRoute.params.origen === 'Tipos de Fallas') {
      this.catalogType = 7
      this.routeRedirect = '/administracion/tipo-falla'
    } else if (router.currentRoute.params.origen === 'Tipos de Lamparas') {
      this.catalogType = 9
      this.routeRedirect = '/administracion/tipo-lamparas'
      this.havePrice = true
      this.nameTable = 'tipo_lampara'
    } else if (router.currentRoute.params.origen === 'Unidades Varias') {
      this.catalogType = 10
      this.routeRedirect = '/administracion/unidades-varias'
      this.havePrice = true
      this.nameTable = 'unidades_varias'
    } else if (router.currentRoute.params.origen === 'Grupos') {
      this.catalogType = 12
      this.routeRedirect = '/administracion/grupos'
    } else if (
      router.currentRoute.params.origen === 'Sistema de Comunicación'
    ) {
      this.catalogType = 13
      this.routeRedirect = '/administracion/sistema-comunicacion'
    } else if (router.currentRoute.params.origen === 'Tipo de Brazo') {
      this.catalogType = 14
      this.routeRedirect = '/administracion/tipo-brazos'
      this.havePrice = true
      this.nameTable = 'tipo_brazo'
    } else if (router.currentRoute.params.origen === 'Tipos de Falla BT') {
      this.catalogType = 15
      this.routeRedirect = '/administracion/tipo-falla-bt'
      this.havePrice = false
      this.nameTable = 'tipo_falla_bt'
    } else if (router.currentRoute.params.origen === 'Trabajos Conservación') {
      this.catalogType = 16
      this.routeRedirect = '/administracion/trabajos-conservacion'
      this.havePrice = false
      this.nameTable = 'trabajos_conservacion'
    } else if (router.currentRoute.params.origen === 'Trabajos Brecha') {
      this.catalogType = 17
      this.routeRedirect = '/administracion/trabajos-brecha'
      this.havePrice = false
      this.nameTable = 'trabajos_brecha'
    } else if (router.currentRoute.params.origen === 'Trabajos Gateway') {
      this.catalogType = 18
      this.routeRedirect = '/administracion/trabajos-gateway'
      this.havePrice = false
      this.nameTable = 'trabajos_gateway'
    } else if (
      router.currentRoute.params.origen === 'Trabajo Centros de Mando P&M'
    ) {
      this.catalogType = 19
      this.routeRedirect = '/administracion/trabajos-gabinete'
      this.havePrice = false
      this.nameTable = 'trabajos_gabinete'
    } else if (
      router.currentRoute.params.origen === 'Fallas Iniciales Ticket'
    ) {
      this.catalogType = 20
      this.routeRedirect = '/administracion/fallas-iniciales-ticket'
      this.havePrice = false
      this.nameTable = 'fallas_iniciales_ticket'
    } else if (router.currentRoute.params.origen === 'Fallas Lámparas Bodega') {
      this.catalogType = 21
      this.routeRedirect = '/administracion/fallas-lamparas-bodega'
      this.havePrice = false
      this.nameTable = 'fallas_lamparas_bodega'
    } else if (
      router.currentRoute.params.origen === 'Pre-Diagnóstico Lámparas Bodega'
    ) {
      this.catalogType = 22
      this.routeRedirect = '/administracion/prediagnostico-lamparas-bodega'
      this.havePrice = false
      this.nameTable = 'fallas_lamparas_bodega'
    } else if (
      router.currentRoute.params.origen === 'Pruebas Lámparas Bodega'
    ) {
      this.catalogType = 23
      this.routeRedirect = '/administracion/pruebas-lamparas-bodega'
      this.havePrice = false
      this.nameTable = 'fallas_lamparas_bodega'
    } else if (
      router.currentRoute.params.origen === 'Reparaciones Laboratorio'
    ) {
      this.catalogType = 24
      this.routeRedirect = '/administracion/reparaciones-laboratorio'
      this.havePrice = false
      this.nameTable = 'reparaciones-laboratorio'
    } else if (router.currentRoute.params.origen === 'Potencias Lámparas') {
      this.catalogType = 25
      this.routeRedirect = '/administracion/potencias'
      this.havePrice = false
      this.nameTable = 'potencia_lampara'
    } else if (router.currentRoute.params.origen === 'Herrajes') {
      this.catalogType = 26
      this.routeRedirect = '/administracion/herrajes'
      this.havePrice = true
      this.nameTable = 'tipo-herrajes'
    } else if (router.currentRoute.params.origen === 'Tipos Conexiones') {
      this.catalogType = 28
      this.routeRedirect = '/administracion/conexiones'
      this.havePrice = false
      this.nameTable = 'tipo_conexion'
    } else if (
      router.currentRoute.params.origen === 'Tipos de Postes Levantamiento'
    ) {
      this.catalogType = 29
      this.routeRedirect = '/administracion/tipo-poste-levantamiento'
      this.havePrice = false
      this.nameTable = 'tipo_poste_levantamiento'
    } else if (router.currentRoute.params.origen === 'Trabajos Levantamiento') {
      this.catalogType = 30
      this.routeRedirect = '/administracion/trabajos-levantamiento'
      this.havePrice = true
      this.nameTable = 'unidades_varias'
    } else if (router.currentRoute.params.origen === 'Dispositivo Lámparas') {
      this.catalogType = 31
      this.routeRedirect = '/administracion/dispositivos-lampara'
      this.havePrice = false
      this.nameTable = 'tipo_dispositivo'
    } else if (router.currentRoute.params.origen === 'Tamaños Accesorios') {
      this.catalogType = 32
      this.routeRedirect = '/administracion/tamanos-accesorios'
      this.havePrice = false
      this.nameTable = 'tamanos-unidades'
    } else if (router.currentRoute.params.origen === 'Trabajo Correctivo') {
      this.catalogType = 33
      this.routeRedirect = '/administracion/trabajos-correctivos'
      this.havePrice = true
      this.mostrarValoresNocturnos = true
      this.nameTable = 'unidades_varias'
      this.addAdditionalColumns()
    } else {
      return
    }
    await this.cargarItem()
  },
  methods: {
    addAdditionalColumns() {
      this.tableColumns.splice(
        2,
        0,
        {
          label: 'Precio Costo Nocturno',
          field: 'costoNocturno',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Precio de Venta Nocturno',
          field: 'precioVentaNocturno',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      )
    },
    async updateItem() {
      if (this.item.tipo === 'CORRECTIVO') {
        if (this.item.tipoTrabajo === true) {
          this.item.tipoTrabajo = 'CAMBIO_LAMPARA'
        } else {
          this.item.tipoTrabajo = ''
        }
      }
      const result = await saveItem(
        this.catalogType,
        this.item,
        this.catalogoName,
        2,
      )
      if (result !== null) {
        this.$router.replace(this.routeRedirect).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} Actualizado!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} actualizado correctamente!`,
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no Actualizado`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no Actualizado!`,
          },
        })
      }
    },
    async cancel() {
      if (router.currentRoute.params.action === 'Edit') await this.$router.replace(this.routeRedirect)
      else await this.cargarItem()
    },
    async cargarPrecios() {
      this.precios = await getPreciosByCatalogo(
        this.nameTable,
        router.currentRoute.params.id,
      )
    },
    async cargarItem() {
      try {
        const result = await getItem(
          this.catalogType,
          router.currentRoute.params.id,
        )
        if (result !== null) {
          if (this.havePrice) {
            await this.cargarPrecios()
          }
          // eslint-disable-next-line prefer-destructuring
          this.item = result
          if (this.catalogType === 33) {
            this.item.tipoTrabajo = this.item.tipoTrabajo === 'CAMBIO_LAMPARA'
          }
          this.item.active = this.item.active
            ? {
              title: 'ACTIVO',
              icon: 'ToggleRightIcon',
              value: true,
            }
            : {
              title: 'INACTIVO',
              icon: 'ToggleLeftIcon',
              value: false,
            }
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
    checkFormValidity() {
      this.costoState = this.itemNewPrecio.costo.toString().trim() !== ''
      this.ventaState = this.itemNewPrecio.precioVenta.toString().trim() !== ''
      if (this.mostrarValoresNocturnos) {
        this.costoStateNocturno = this.itemNewPrecio.costoNocturno.toString().trim() !== ''
        this.ventaStateNocturno = this.itemNewPrecio.precioVentaNocturno.toString().trim() !== ''
        return this.costoState && this.ventaState && this.costoStateNocturno && this.ventaStateNocturno
      }
      return this.costoState && this.ventaState
    },
    resetModal() {
      this.itemNewPrecio.precioVenta = ''
      this.itemNewPrecio.costo = ''
      this.itemNewPrecio.precioVentaNocturno = ''
      this.itemNewPrecio.costoNocturno = ''
      this.costoState = null
      this.ventaState = null
      this.costoStateNocturno = null
      this.ventaStateNocturno = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    mensajeError(mensaje) {
      Swal.fire({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      try {
        this.itemNewPrecio.tablaOrigen = this.nameTable
        this.itemNewPrecio.idOrigen = Number(this.itemNewPrecio.idOrigen)
        console.log(this.itemNewPrecio)
        const resulta = await saveItem(
          11,
          JSON.stringify(this.itemNewPrecio),
          'Histórico Precio',
          1,
        )
        if (resulta !== null) {
          await this.cargarPrecios()
        } else {
          this.mensajeError('Error Almacenando Valores, Intente Nuevamente')
        }
      } catch (err) {
        console.error('Error guardando precios', err)
        this.mensajeError('Error Almacenando Valores, Intente Nuevamente')
      }
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
